import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Natura = () => {
  return (
    <>
      <GatsbySeo
        title="Natura Viewer"
        description="Natura 2000 è una rete ecologica di aree protette, istituita per garantire la sopravvivenza delle specie e degli habitat più preziosi d'Europa. Natura 2000 si basa sulla direttiva Habitat del 1992, ma incorpora anche siti designati ai sensi della direttiva Uccelli del 1979. Di conseguenza, i singoli siti possono essere designati o ai sensi della Direttiva Uccelli (ZPS - visualizzate con tratteggio rosso), ai sensi della Direttiva Habitat (SIC / SIC / ZSC - visualizzate con tratteggio blu) o sotto entrambe le direttive (visualizzate sia con tratteggio rosso che blu) .
        La rete Natura 2000 comprende attualmente oltre 27.300 siti individuali, che complessivamente coprono un'area di oltre 1,1 milioni di chilometri quadrati. Ciò corrisponde al 18% della superficie terrestre europea degli Stati membri dell'UE e ca. 4% della loro area marina europea."
        canonical="https://www.casafraine.com/natura-viewer"
        openGraph={{
          url: "https://www.casafraine.com/natura-viewer",
          title: "Natura Viewer",
          description:
            "L'idea e' quella di vivere nella maniera piu' green possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico",

          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "Botanic Api",
            },
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 900,
              height: 800,
              alt: "Botanic Api",
            },
            { url: "https://www.casafraine.com/mapitaly.png" },
            { url: "https://www.casafraine.com/mapitaly.png" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <section className="booking">
        <center>
          <iframe
            src="https://natura2000.eea.europa.eu/"
            width="925"
            height="550"
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
          <section>
            <h2>Fraine in depth:</h2>
            <a
              href="https://natura2000.eea.europa.eu/Natura2000/SDF.aspx?site=IT7140210&release=10"
              target="_blank"
              rel="canonical noopener noreferrer"
            >
              here
            </a>
          </section>
        </center>
      </section>
    </>
  );
};

export default Natura;
